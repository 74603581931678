import CreateBaseRequest from '../BaseRequest';
import ENDPOINTS from '../Endpoints';

const ConfiguracaoAPI = {
  listarConfiguracaoInicial: () => {
    return CreateBaseRequest(null, { timeout: 30000 }).get(ENDPOINTS.configuracao.listarConfiguracaoInicial);
  },
};

export default ConfiguracaoAPI;
